import Backdrop from "@material-ui/core/Backdrop";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  logo: {
    width: " 15%",
  },
}));

export default function AppLoader() {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true} onClick={() => null}>
      <img src="assets/images/logo.png" className={classes.logo} />
    </Backdrop>
  );
}
