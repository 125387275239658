//  © copyright 2022, Innova Healthcare, LLC

import React, { Suspense, lazy } from "react";
import { render } from "react-dom";
import AppLoader from "./components/atoms/AppLoader";

const App = lazy(() => import("./app"));

const target = document.getElementById("root");

render(
  <Suspense fallback={<AppLoader />}>
    <App />
  </Suspense>,
  target
);
